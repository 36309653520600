"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var faCheck_1 = require("@fortawesome/pro-light-svg-icons/faCheck");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var React = __importStar(require("react"));
var CheckMarkIcon = function (_a) {
    var className = _a.className, _b = _a.circled, circled = _b === void 0 ? false : _b, _c = _a.solid, solid = _c === void 0 ? false : _c;
    return circled ? (React.createElement("div", { style: { height: '1em', width: '1em' }, className: "flex flex-shrink-0 items-center justify-center rounded-full border ".concat(solid
            ? 'bg-v2-accent border-v2-accent text-white'
            : 'border-opacity-50 hover:border-v2-accent hover:bg-v2-accent hover:border-opacity-100 text-transparent hover:text-white', " ").concat(className !== null && className !== void 0 ? className : '') },
        React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: faCheck_1.faCheck, style: { fontSize: '0.6em' } }))) : (React.createElement("svg", { width: "11", height: "8", viewBox: "0 0 11 8", fill: "none", xmlns: "http://www.w3.org/2000/svg", style: {
            marginRight: -1,
        }, className: className },
        React.createElement("path", { d: "M10.2703 0.987359L9.30276 0.0577963C9.2692 0.0192653 9.21645 0 9.16851 0C9.11576 0 9.06782 0.0192653 9.03425 0.0577963L3.30357 5.79383L1.42486 3.64546C1.3865 3.60693 1.33855 3.58767 1.29061 3.58767C1.24266 3.58767 1.19471 3.60693 1.15636 3.64546L0.0575367 4.75376C-0.0191789 4.83083 -0.0191789 4.95123 0.0575367 5.0283L2.74258 7.72547C2.91519 7.89886 3.12616 8 3.30357 8C3.55769 8 3.77824 7.81216 3.85975 7.7351H3.86455L10.2751 1.26189C10.3422 1.18001 10.3422 1.0596 10.2703 0.987359Z", fill: "currentColor" })));
};
exports.default = CheckMarkIcon;
